//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import axios from 'axios';
import LiveCentersPlaceholder from '~/components/component/LiveCentersPlaceholder';
import {ModuleLiveCenters} from '@nhst/design-system/dist/ModuleLiveCenters';
import hosts from '@/config/hosts.json';
import moment from 'moment-timezone';

export default {
  name: 'LiveCenters',
  components: {ModuleLiveCenters, LiveCentersPlaceholder},
  props: {
    liveCenterMaxContents: {
      type: Number,
      default: 10
    },
    liveCenterIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: 'lantern_placeholder_live-centers-1',
    },
    showTimeDifference: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      liveCenterFeeds: [],
      module: 'module-live-centers-1',
    }
  },
  computed: {
    ...mapState(['nodeEnv', 'locals']),
  },
  created() {
    this.module = this.placeholder === 'lantern_placeholder_live-centers-1'
      ? 'module-live-centers-1' : 'module-live-centers-2';
  },
  mounted() {
    this.getLiveCenterFeed();
  },
  methods: {
    getRequestUrl() {
      const ids = this.liveCenterIds ? this.liveCenterIds.map(obj => obj.id).join() : '';
      let url = new URL(this.locals.widgetHostHttps + '/v1/livecenters');
      url.searchParams.append('ids', JSON.stringify(ids).replace(/['"]+/g, ''));
      url.searchParams.append('count', this.liveCenterMaxContents.toString());
      return url.toString();
    },
    async getLiveCenterFeed() {
      const feeds = await axios.get(this.getRequestUrl());
      moment.locale('nb');
      const startOfToday = moment().utc().tz('Europe/Oslo').startOf('day');
      this.liveCenterFeeds = feeds.data.map(feed => {
        const {timestamp, created, ...rest} = feed;
        let createdDate = '';
        if (created) {
          createdDate = moment(new Date(created * 1000)).utc().tz('Europe/Oslo');
          if (createdDate < startOfToday) {
            createdDate = createdDate.format('HH.mm DD.MM.YY');
          } else {
            createdDate = createdDate.format('HH.mm');
          }
        }
        return {
          createdTime: this.showTimeDifference ? timestamp : createdDate,
          ...rest
        };
      })
    },
    getProxyHost() {
      return hosts[this.nodeEnv].lantern_proxy_endpoint;
    },
    errorCaptured: function (err) {
      console.error(`Caught error from children of LiveCenters component:: ${err.message}`);
    },
  }
}
